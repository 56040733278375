import React from "react"
import Layout from "../components/layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const FactorPage = ({ data }) => {
  return (
    <Layout>
      <h1>Factoring Services</h1>
      <div style={{ float: `left`, maxWidth: `800px` }}>
        <h2>Trinity Factors</h2>
        <hr />
        <p>
          The Caledonian Village development is managed by{" "}
          <b>
            <OutboundLink href="http://www.trinityfactors.co.uk">
              Trinity Factoring Services
            </OutboundLink>
          </b>
          , 209 Bruntsfield Place, Edinburgh, EH10 4DH. They can be contacted on
          0131 447 9911 or via email at{" "}
          <a href="mailto:admin@trinityfactors.co.uk">
            admin@trinityfactors.co.uk
          </a>
          .
        </p>
        <p>
          As property manager, Trinity Factors carry out regular inspections
          around Caledonian Village and arrange for the maintenance and upkeep
          of the common aspects of the development, including the regular
          contracts for stair cleaning, landscaping, and pest control and deal
          with all necessary repairs. Trinity Factors have an in-house
          maintenance team that attends to smaller items such as altering time
          clocks, small items of joinery and other general works. Larger repairs
          and maintenance works will be put to tender to specialist firms and
          owners will be notified of such items, and advised of any additional
          costs.
        </p>

        <p>
          Trinity Factors are the first point of contact for any claims under
          the common block insurance policy.
        </p>

        <p>
          There is a dedicated property manager usually on duty between 9am -
          5pm, Monday to Thursday, and 9am - 12pm on Friday. However if they are
          unavailable, it is possible for other managers to assist up until 5pm
          on all weekdays.
        </p>

        <h4>
          <OutboundLink href="https://www.trinityfactors.co.uk/wp-content/uploads/sla_v6_2020.pdf">
            Trinity Factor's Service Level Agreement
          </OutboundLink>
        </h4>

        <h3>Emergencies</h3>
        <p>
          Trinity Factors provide an emergency service outside normal working
          hours, 365 days a year. This service allows any proprieter or resident
          to phone their call centre 24/7 on{" "}
          <a href="tel:+441314479911">0131 447 9911</a> in the event of an
          emergency. Their highly trained staff at the call centre have access
          to the development's list of approved contractors, and will contact
          the appropriate emergency contractor to arrange all necessary repairs.
        </p>

        <h3>Reporting repairs</h3>

        <p>
          Please report anything which is a cause for concern and may require
          attention to the property manager as soon as possible, giving as much
          information as you can with regard to the nature and location of the
          problem. Include your contact details, and if possible, a photograph
          if relevant. Example issues might be lights not working in the
          stairwells or outside the main doors or in the car parks, water
          leaking from an overflow, problems with an entry door etc.
        </p>

        <blockquote>
          <ul>
            <li>
              <OutboundLink href="http://www.trinityfactors.co.uk/repair">
                Report a repair
              </OutboundLink>
            </li>
          </ul>
        </blockquote>
      </div>
    </Layout>
  )
}

export default FactorPage
